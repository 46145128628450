export class GameStatsCounter {
  constructor(sessionId) {
    this.bet = 0;
    this.win = 0;
    this.loss = 0;
    this.netLoss = 0;
    this.gameName = '';
    this.sessionId = sessionId;
  }

  updateStats(event) {
    console.log('COUNTER_UPDATE', event);
    const { type, data } = event;
    if (type === 'games') {
      /**
       * ActivityType: 1 = bet, 2 = win, 7 = end
       */
      var activityType = data.ActivityType;
      var amount = data.Amount;
      var balanceAfter = data.BalanceAfter;
      var balanceBefore = data.BalanceBefore;
      var prevLoss = parseFloat(this.loss);
      var newLoss = 0;

      if (!this.gameName) {
        this.gameName = data.GameName;
      }

      if (activityType === 1) {
        var prevBet = parseFloat(this.bet);
        var newBet = prevBet + amount;
        newLoss = prevLoss - (balanceBefore - balanceAfter);
        this.bet = newBet;
        this.loss = newLoss;
        this.netLoss = parseFloat(this.netLoss) - parseFloat(amount);
        this.emitStats();
      }

      if (activityType === 2) {
        var prevWin = parseFloat(this.win);
        var newWin = prevWin + amount;
        newLoss = prevLoss + (balanceAfter - balanceBefore);
        this.win = newWin;
        this.loss = newLoss;
        this.netLoss = parseFloat(this.netLoss) + parseFloat(amount);
        this.emitStats();
      }
    }
  }

  emitStats() {
    window.dispatchEvent(
      new CustomEvent('live-stat-update', {
        detail: {
          bet: this.bet,
          win: this.win,
          loss: this.loss,
          netLoss: this.netLoss,
          gameName: this.gameName,
          sessionId: this.sessionId,
        },
      })
    );
  }

  resetStats() {
    this.bet = 0;
    this.win = 0;
    this.loss = 0;
    this.netLoss = 0;
  }
}
