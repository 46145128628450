import content from './template.html';

class CampaignGame extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    const template = document.createElement('template');
    template.innerHTML = content;
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.btnLeft = this.shadowRoot.querySelector('.btn-left');
    this.btnRight = this.shadowRoot.querySelector('.btn-right');
    this.closeBtn = this.shadowRoot.querySelector('.btn-close');
    this.btnRandomSpin = this.shadowRoot.querySelector('.random-spin-btn');
    this.btnClaimNow = this.shadowRoot.querySelector('.pick-game__winning-popup-btn');
    this.winCardImage = this.shadowRoot.querySelector('img.pick-game__winitemimage');
    this.spinningItems = this.shadowRoot.querySelectorAll('.image_wrapper');
    this.showGenericErrorModal = window.showGenericErrorModal;

    this.prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');

    this.closeModal = Rizk.ModalController.closeModal;

    this.headLinerImage = this.shadowRoot.querySelector('.pick-game__title').firstElementChild;

    this.position = 1;
    this.duration = '1s';
    this.easing = 'ease';
    this.plate = this.shadowRoot.querySelector('.plate');
    this.electrons = this.shadowRoot.querySelectorAll('.electron');
    this.cardAlt = Math.floor(Math.random() * 3);
    this.weekIndex;
  }

  static get observedAttributes() {
    return ['isvisible'];
  }

  connectedCallback() {
    this.weekIndex = this.getAttribute('week');
    this.setupCampaignType(this.getAttribute('type'));
    this.rotateEggsToLeft = this.rotateEggsToLeft.bind(this);
    this.rotateEggsToRight = this.rotateEggsToRight.bind(this);
    this.oneThirdLeftTurn = this.oneThirdLeftTurn.bind(this);
    this.twoThirdLeftTurn = this.twoThirdLeftTurn.bind(this);
    this.threeThirdLeftTurn = this.threeThirdLeftTurn.bind(this);
    this.oneThirdRightTurn = this.oneThirdRightTurn.bind(this);
    this.twoThirdRightTurn = this.twoThirdRightTurn.bind(this);
    this.threeThirdRightTurn = this.threeThirdRightTurn.bind(this);
    this.randomSpin = this.randomSpin.bind(this);
    this.showWinningPopup = this.showWinningPopup.bind(this);

    this.shadowRoot.querySelector('.pick-game__text').innerHTML = this.getAttribute('text');
    this.shadowRoot.querySelector('.pick-game__or').innerText = this.getAttribute('or');
    this.btnRandomSpin.innerText = this.getAttribute('cta');
    this.shadowRoot.querySelector('.pick-game__opt-out').innerHTML = this.getAttribute('optout');
    this.shadowRoot.querySelector('.pick-game__tandc').innerHTML = this.getAttribute('tandc');
    this.shadowRoot.querySelector('.pick-game__winning-popup-btn').innerText = this.getAttribute('claimbuttontext');

    this.closeBtn.addEventListener('click', () => {
      this.closeModal(this.getAttribute('modalid'));
    });
    this.btnLeft.addEventListener('click', this.rotateEggsToLeft);
    this.btnRight.addEventListener('click', this.rotateEggsToRight);
    this.btnRandomSpin.addEventListener('click', this.randomSpin);
    this.spinningItems.forEach((spinningItem) => {
      spinningItem.addEventListener('click', (e) => {
        this.showWinningPopup(e);
      });
    });
    this.btnClaimNow.addEventListener('click', (e) => {
      this.closeModal(this.getAttribute('modalid'));
      Rizk.Rewards.rewardButtonClick(e);
    });
    this.btnClaimNow.addEventListener('touchend', (e) => {
      this.closeModal(this.getAttribute('modalid'));
      Rizk.Rewards.rewardButtonClick(e);
    });
    this.shadowRoot.querySelector('.pick-game__opt-out').addEventListener('click', () => {
      this.optOut();
    });
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === 'isvisible' && newValue === 'true') {
      const fullwrapper = this.shadowRoot.querySelector('.full-wrapper');
      fullwrapper.style.visibility = 'visible';
    }
  }
  setupCampaignType(type) {
    this.setupImages();
  }

  setupImages() {
    ajax('/campaign-headliner/get-campaign', (response) => {
      console.log('AFTER 404');
      let json = JSON.parse(response.responseText);
      if (!json.headliner) {
        this.showGenericErrorModal();
        console.log('ERROR CAMPAIGN');
        return;
      }
      const headlinerData = json.headliner;
      const englishLocales = RizkConfig.english_locales;
      const locale = englishLocales.includes(RizkConfig.locale) ? 'en' : RizkConfig.locale;
      this.shadowRoot.querySelectorAll('img.item').forEach((image, index) => {
        //image.src = `/assets/img/campaign-game/${type}${index + 1}.svg`
        image.src =
          RizkConfig.staticAssetsUrl + `headliner/thumb${index + 1}/` + headlinerData[`thumb${index + 1}_image`];
        console.log(image.src);
      });
      this.shadowRoot.querySelector('img.pick-game__winitemimage').src =
        RizkConfig.staticAssetsUrl + 'headliner/logo/' + headlinerData.logo_image;
      this.headLinerImage.src = RizkConfig.staticAssetsUrl + `headliner/title/${locale}/` + headlinerData.title_image; //remove 'title.svg' and add this when title is per locale -> ${locale}.png
      this.headLinerImage.alt = this.getAttribute('title');
    });
  }
  optOut() {
    const optOutPromise = new Promise((resolve) => {
      ajax('/pick-game/pick/opt-out', () => {
        resolve();
      });
    });
    optOutPromise
      .then(() => {
        return Rizk.Inbox.getUnreadPromise();
      })
      .then(() => {
        this.closeModal(this.getAttribute('modalid'));
      });
  }

  oneThirdLeftTurn(duration, easing, nextPosition) {
    return new Promise((resolve) => {
      this.plate.style.animation = `plateOneThirdLeftTurn ${duration} ${easing} forwards`;
      this.electrons.forEach(
        (electron) => (electron.style.animation = `itemOneThirdLeftTurn ${duration} ${easing} forwards`)
      );
      this.plate.addEventListener('animationend', (e) => {
        const target = e.target;
        if (target.matches('.plate')) {
          resolve(nextPosition);
        }
      });
    });
  }

  twoThirdLeftTurn(duration, easing, nextPosition) {
    return new Promise((resolve) => {
      this.plate.style.animation = `plateTwoThirdLeftTurn ${duration} ${easing} forwards`;
      this.electrons.forEach(
        (electron) => (electron.style.animation = `itemTwoThirdLeftTurn ${duration} ${easing} forwards`)
      );
      this.plate.addEventListener('animationend', (e) => {
        const target = e.target;
        if (target.matches('.plate')) {
          resolve(nextPosition);
        }
      });
    });
  }

  threeThirdLeftTurn(duration, easing, nextPosition) {
    return new Promise((resolve) => {
      this.plate.style.animation = `plateThreeThirdLeftTurn ${duration} ${easing} forwards`;
      this.electrons.forEach(
        (electron) => (electron.style.animation = `itemThreeThirdLeftTurn ${duration} ${easing} forwards`)
      );
      this.plate.addEventListener('animationend', (e) => {
        const target = e.target;
        if (target.matches('.plate')) {
          resolve(nextPosition);
        }
      });
    });
  }

  oneThirdRightTurn(duration, easing, nextPosition) {
    return new Promise((resolve) => {
      this.plate.style.animation = `plateOneThirdRightTurn ${duration} ${easing} forwards`;
      this.electrons.forEach(
        (electron) => (electron.style.animation = `itemOneThirdRightTurn ${duration} ${easing} forwards`)
      );
      this.plate.addEventListener('animationend', (e) => {
        const target = e.target;
        if (target.matches('.plate')) {
          resolve(nextPosition);
        }
      });
    });
  }

  twoThirdRightTurn(duration, easing, nextPosition) {
    return new Promise((resolve) => {
      this.plate.style.animation = `plateTwoThirdRightTurn ${duration} ${easing} forwards`;
      this.electrons.forEach(
        (electron) => (electron.style.animation = `itemTwoThirdRightTurn ${duration} ${easing} forwards`)
      );
      this.plate.addEventListener('animationend', (e) => {
        const target = e.target;
        if (target.matches('.plate')) {
          resolve(nextPosition);
        }
      });
    });
  }

  threeThirdRightTurn(duration, easing, nextPosition) {
    return new Promise((resolve) => {
      this.plate.style.animation = `plateThreeThirdRightTurn ${duration} ${easing} forwards`;
      this.electrons.forEach(
        (electron) => (electron.style.animation = `itemThreeThirdRightTurn ${duration} ${easing} forwards`)
      );
      this.plate.addEventListener('animationend', (e) => {
        const target = e.target;
        if (target.matches('.plate')) {
          resolve(nextPosition);
        }
      });
    });
  }

  async showWinningPopup(e) {
    if (e) {
      this.cardAlt = e.target.alt.split(' ')[1] - 1;
    }
    this.setupCampaignType(this.getAttribute('type'));
    const popupwrapper = this.shadowRoot.querySelector('.winning-popup-wrapper');
    const popup = this.shadowRoot.querySelector('.winning-popup');

    ajax(`/pick-game/pick/${this.getAttribute('itemid')}/1`, (response) => {
      const json = JSON.parse(response.responseText);
      if (json.success === 'false') {
        this.showGenericErrorModal();
        return;
      }
      this.shadowRoot.querySelector('.pick-game__wintitle').innerText = json.texts.text3;
      this.shadowRoot.querySelector('.pick-game__wonitem').innerText = `${json.texts.text2} ${json.texts.text1}`;
    });
    popupwrapper.style.display = 'flex';
    if (!this.prefersReducedMotion || this.prefersReducedMotion.matches) {
      popup.style.transform = 'translateY(0px)';
    }

    setTimeout(() => (popup.style.transform = 'translateY(0px)'), 500);
  }

  rotateEggsToLeft() {
    this.btnLeft.disabled = true;
    this.btnRight.disabled = true;
    if (this.position === 1) {
      const nextPosition = 2;
      this.oneThirdLeftTurn(this.duration, this.easing, nextPosition).then((currPos) => {
        this.position = currPos;
        this.btnLeft.disabled = false;
        this.btnRight.disabled = false;
      });
    }
    if (this.position === 2) {
      const nextPosition = 3;
      this.twoThirdLeftTurn(this.duration, this.easing, nextPosition).then((currPos) => {
        this.position = currPos;
        this.btnLeft.disabled = false;
        this.btnRight.disabled = false;
      });
    }
    if (this.position === 3) {
      const nextPosition = 1;
      this.threeThirdLeftTurn(this.duration, this.easing, nextPosition).then((currPos) => {
        this.position = currPos;
        this.btnLeft.disabled = false;
        this.btnRight.disabled = false;
      });
    }
  }

  rotateEggsToRight() {
    this.btnLeft.disabled = true;
    this.btnRight.disabled = true;
    if (this.position === 1) {
      const nextPosition = 3;
      this.oneThirdRightTurn(this.duration, this.easing, nextPosition).then((currPos) => {
        this.position = currPos;
        this.btnLeft.disabled = false;
        this.btnRight.disabled = false;
      });
    }
    if (this.position === 3) {
      const nextPosition = 2;
      this.twoThirdRightTurn(this.duration, this.easing, nextPosition).then((currPos) => {
        this.position = currPos;
        this.btnLeft.disabled = false;
        this.btnRight.disabled = false;
      });
    }
    if (this.position === 2) {
      const nextPosition = 1;
      this.threeThirdRightTurn(this.duration, this.easing, nextPosition).then((currPos) => {
        this.position = currPos;
        this.btnLeft.disabled = false;
        this.btnRight.disabled = false;
      });
    }
  }

  randomSpin() {
    this.btnRandomSpin.disabled = true;
    //remove and replate the old plate to get rid of all potential hung up event listeners
    const oldPlate = this.shadowRoot.querySelector('.plate');
    const plate = oldPlate.cloneNode(true);
    oldPlate.parentNode.replaceChild(plate, oldPlate);
    const time = '0.5s';
    const electrons = this.shadowRoot.querySelectorAll('.electron');

    const additionalTurns = Math.floor(Math.random() * 5) + 3;

    let currentState = 0;

    const states = [
      () => {
        plate.style.animation = `plateThreeThirdLeftTurn ${time} linear forwards`;
        electrons.forEach((electron) => (electron.style.animation = `itemThreeThirdLeftTurn ${time} linear forwards`));
      },
      () => {
        plate.style.animation = `plateOneThirdLeftTurn ${time} linear forwards`;
        electrons.forEach((electron) => (electron.style.animation = `itemOneThirdLeftTurn ${time} linear forwards`));
      },
      () => {
        plate.style.animation = `plateTwoThirdLeftTurn ${time} linear forwards`;
        electrons.forEach((electron) => (electron.style.animation = `itemTwoThirdLeftTurn ${time} linear forwards`));
      },
    ];

    const animateToNextState = () => {
      if (additionalTurns > currentState) {
        requestAnimationFrame(() => {
          states[++currentState % states.length]();
        });
      }
    };
    let counter = 0;
    plate.addEventListener('animationend', (e) => {
      const target = e.target;
      if (target.matches('.plate')) {
        console.log(target);
        animateToNextState();
        counter++;
        console.log('Additional Turns', additionalTurns, 'Counter', counter);
        if (counter === additionalTurns) {
          setTimeout(() => {
            this.showWinningPopup();
          }, 500);
        }
      }
    });
    animateToNextState();
  }
}

export default CampaignGame;
